<script>
import appConfig from "@/app.config";

import Swal from "sweetalert2";
import axios from "axios";
/**
 * Dashboard Component
 */
export default {
    page: {
        title: "Tambah Jabatan",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    components: {
    },
    data() {
        return {
            title: "Tambah Jabatan",
            items: [
                {
                    text: "Master",
                    href: "/",
                },
                {
                    text: "Jabatan",
                    href: "/master/Jabatan",
                },
                {
                    text: "Tambah Jabatan",
                    active: true,
                },
            ],
            // Catch Error Axios
            axiosCatchError: null,
            // variable Page
            kategori: ['konsultan', 'vendor'],
            kategori_selected: "",
            tipe: "",
        };
    },
    mounted() { },
    methods: {
        StoreData() {
            let self = this;
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });
            var FormData = require("form-data");
            var data = new FormData();
            data.append("tipe", self.tipe);
            data.append("kategori", self.kategori_selected);

            var config = {
                method: "post",
                url:
                    process.env.VUE_APP_BACKEND_URL_VERSION + "/api/master/tipe/store",
                data: data,
            };
            axios(config)
                .then(function (response) {
                    var response_data = response.data;
                    if (response_data.code != 200) {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            html: response_data.message,
                        });
                    } else {
                        let timerInterval;
                        Swal.fire({
                            icon: "success",
                            title: "Berhasil",
                            text: "Anda akan diarahkan ke halaman master tipe segera",
                            timer: 2000,
                            timerProgressBar: true,
                            showCancelButton: false,
                            showConfirmButton: false,
                            willClose: () => {
                                clearInterval(timerInterval);
                            },
                        }).then((result) => {
                            /* Read more about handling dismissals below */
                            if (result.dismiss === Swal.DismissReason.timer) {
                                self.$emit("refresh-table", "add");
                            }
                        });
                    }
                })
                .catch((e) => {
                    this.axiosCatchError = e.response.data.data;
                    Swal.close();
                });
        },
    },
};
</script>

<template>
    <div class="row">
        <div class="col-lg-12">
            <b-form class="p-2" @submit.prevent="StoreData">
                <div class="row">
                    <div class="col-md-12">
                        <b-form-group class="mb-3" label="Kategori">
                            <v-select placeholder="-Pilih Kategori-" :options="kategori"
                                v-model="kategori_selected"></v-select>
                        </b-form-group>
                        <b-form-group class="mb-3" label="Tipe">
                            <b-form-input type="text" v-model="tipe"></b-form-input>
                        </b-form-group>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 text-end">
                        <b-button type="submit" variant="primary" class="m-1"><i class="fa fa-save"></i> Simpan</b-button>
                    </div>
                </div>
            </b-form>
        </div>
    </div>
</template>
